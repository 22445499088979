import { GenericFlowEvent } from "@mfe/tef-tracking-types";
import { GenericFlowEventDL } from "#/dataLayer";

export const mapGenericFlow = (evt: GenericFlowEvent): GenericFlowEventDL => {
    const flowName = evt.flowName ?? "na";
    const categoryValue = evt.topic ? `${evt.topic} - ${flowName}` : "eec";
    const actionKey = `generic_${flowName}_${evt.action}`;
    const label = evt.label ?? "-";
    const nonInteraction = !(evt.action == "click" || evt.action == "submit" || evt.action == "change");
    const value = evt.action == "upload_finish" ? evt.durationInSeconds ?? 0 : 0;

    const detail = {
        category: categoryValue,
        action: `${evt.action} - ${evt.intention}`,
        label: label,
        non_interaction: nonInteraction,
        value: value,
        message: evt.message ?? "-",
    };

    return {
        event: actionKey,
        event_name: flowName,
        [actionKey]: detail,
    } as GenericFlowEventDL;
};
